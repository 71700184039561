/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CardSeverity {
    YC = 0,
    Y2C = 1,
    RC = 2,
}
