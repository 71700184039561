/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AuditLogAction {
    CANCEL = 'CANCEL',
    CREATE = 'CREATE',
    DELETE = 'DELETE',
    DELETE_FLAG = 'DELETE_FLAG',
    EXTEND = 'EXTEND',
    SHORTEN = 'SHORTEN',
    SOFT_DELETE = 'SOFT_DELETE',
    UNDO_SOFT_DELETE = 'UNDO_SOFT_DELETE',
    UPDATE = 'UPDATE',
}
