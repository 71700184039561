/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FeaturedCarouselLayout {
    EXPANDED_SIDE = 'expandedSide',
    MINIFIED_SIDE = 'minifiedSide',
    TWIN_MAINS = 'twinMains',
    EXPANDED_FOOTER = 'expandedFooter',
}
