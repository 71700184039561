/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum VideofeedStatus {
    NEW = 'new',
    ACTIVE = 'active',
    ACCEPTED = 'accepted',
    REJECTED = 'rejected',
}
