/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CommentStatusFilter {
    ALL = 'all',
    SHADOW = 'shadow',
    UNREAD = 'unread',
    SUSPECT = 'suspect',
    FLAG = 'flag',
}
