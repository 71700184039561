import { PlatformID } from '@common/clients/api';

export const getPlatformIdByHost = (host: string): PlatformID | undefined => {
    if (/voetbalnieuws|\bvn\b/.test(host)) return PlatformID.VN;
    else if (/voetbalprimeur|\bvp\b/.test(host)) return PlatformID.VP;
    else if (/gpblog|\bgp\b/.test(host)) return PlatformID.GP;
    else if (/bright|\bbr\b/.test(host)) return PlatformID.BR;
    else if (/\bvi\b/.test(host)) return PlatformID.VI;
    return undefined;
};
