import Cookies from 'cookies';
import JsCookies from 'js-cookie';
import newrelic from 'newrelic';
import { GetServerSidePropsContext } from 'next';
import absoluteUrl from 'next-absolute-url';

import { LocaleID, PlatformID, SubjectID } from '@common/clients/api';
import { getByKey, getWithSuffix, stringTypeCheck } from '@common/envvars';

import { CaptchaProps } from './CaptchaProps';
import { ApiProps } from './ApiProps';
import { ContextData } from './ContextData';
import { CrossPlatformByPlatformId } from './CrossPlatformByPlatformId';
import { getPlatformIdByHost } from './getPlatformIdByHost';

const getJSONfromCookie = (cookies: Cookies | typeof JsCookies, key: string): any => {
    try {
        return JSON.parse(cookies.get(key) as string);
    } catch (ex) {
        return {};
    }
};

const getCaptchaConfig = (platform: PlatformID): CaptchaProps => {
    const envVars = {
        NEXT_PUBLIC_CAPTCHA_SITE_KEY: false,
    };
    const values = getWithSuffix(platform, envVars);
    return {
        siteKey: values.NEXT_PUBLIC_CAPTCHA_SITE_KEY ?? '',
    };
};

const getApiConfig = (suffix: string | undefined): ApiProps => {
    const envVars = {
        NEXT_SERVER_API_HOST: false,
        NEXT_PUBLIC_API_HOST: true,
        NEXT_PUBLIC_API_TIMEOUT: false,
    };
    const values = suffix ? getWithSuffix(suffix, envVars) : process.env;
    return {
        internalHost: values.NEXT_SERVER_API_HOST ?? '',
        host: values.NEXT_PUBLIC_API_HOST ?? '',
        timeout: Number(values.NEXT_PUBLIC_API_TIMEOUT) || 5000,
    };
};

export const populateContextData = (context: GetServerSidePropsContext | false): ContextData => {
    const origin = context ? absoluteUrl(context.req).origin : window.location.origin;
    const url = new URL(origin);
    const hostname = url.hostname;
    const apiSuffixByHostname = context
        ? getByKey('NEXT_SERVER_API_SUFFIX_BY_HOSTNAME', stringTypeCheck)
        : undefined;
    const apiSuffix = apiSuffixByHostname ? apiSuffixByHostname.get(hostname) : undefined;
    const apiConfig = getApiConfig(apiSuffix);

    const viapiConfig: ApiProps = {
        internalHost: process.env.NEXT_SERVER_VI_API_HOST ?? '',
        host: process.env.NEXT_PUBLIC_VI_API_HOST ?? '',
        timeout: Number(process.env.NEXT_PUBLIC_VI_API_TIMEOUT) || 5000,
    };

    const viSocketConfig: ApiProps = {
        host: process.env.NEXT_PUBLIC_VI_SOCKET_HOST ?? '',
        timeout: 0,
    };

    const sportmonksF1Config: ApiProps = {
        internalHost: process.env.NEXT_SERVER_SPORTMONKS_F1_HOST ?? '',
        host: process.env.NEXT_PUBLIC_SPORTMONKS_F1_HOST ?? '',
        timeout: Number(process.env.NEXT_PUBLIC_SPORTMONKS_F1_TIMEOUT) || 5000,
    };

    const crossPlatformConfig: CrossPlatformByPlatformId = process.env.NEXT_PUBLIC_CROSS_PLATFORM_CONFIG
        ? JSON.parse(process.env.NEXT_PUBLIC_CROSS_PLATFORM_CONFIG)
        : {
              gp: {
                  timeout: 5000,
                  host: 'https://api.gpblog.com',
              },
              vp: {
                  timeout: 5000,
                  host: 'https://api.voetbalprimeur.nl',
              },
              vpbe: {
                  timeout: 5000,
                  host: 'https://api.voetbalprimeur.be',
              },
              vn: {
                  timeout: 5000,
                  host: 'https://api.voetbalnieuws.nl',
              },
          };

    // context is passed in NextInjector either as cookies or as script element
    const cookies = context ? new Cookies(context.req, context.res) : JsCookies;
    const cookieContext: any = context
        ? {
              domain: getJSONfromCookie(cookies, 'domain'),
              locale: cookies.get('locale'),
              platform: getJSONfromCookie(cookies, 'platform'),
              userID: cookies.get('userID'),
              userSessionID: cookies.get('userSessionID'),
              assetsBaseHref: cookies.get('assetsBaseHref'),
              magnoliaSessionID: cookies.get('publishing_session'),
              isPlatformContext: cookies.get('isPlatformContext'),
          }
        : JSON.parse((document?.getElementById('_next_context')?.textContent as string) || '{}');

    const platformID = (cookieContext?.domain?.platform as PlatformID) || PlatformID.VP;
    const captchaConfig = getCaptchaConfig(platformID);

    const contextData: ContextData = {
        routePrefix: cookieContext?.domain?.slug ? `/${cookieContext.domain.slug}` : '',
        userID: Number(cookieContext.userID) || 0,
        userSessionID: (cookieContext.userSessionID as string) || '',
        magnoliaSessionID: cookieContext.magnoliaSessionID || '',
        context: {
            domain: {
                domainID: Number(cookieContext?.domain?.domainID) || 1,
                slug: (cookieContext?.domain?.slug as string) || '',
                locale: (cookieContext?.domain?.locale as LocaleID) || LocaleID.NL_NL,
                platform: platformID,
                // Important: this value is currently used in _app.tsx to determine if visitor is on www.* or next.*
                domainUrl: (cookieContext?.domain?.domainUrl as string) || '',
                twitter: (cookieContext?.domain?.domainTwitter as string) || '',
                facebook: (cookieContext?.domain?.domainFacebook as string) || '',
                instagram: (cookieContext?.domain?.domainInstagram as string) || '',
                tiktok: (cookieContext?.domain?.domainTiktok as string) || '',
                youtube: (cookieContext?.domain?.domainYoutube as string) || '',
                address: (cookieContext?.domain?.domainAddress as string) || '',
            },
            platform: {
                id: (cookieContext?.platform?.id as PlatformID) || PlatformID.VP,
                name: (cookieContext?.platform?.name as string) || 'VoetbalPrimeur',
                subject: (cookieContext?.platform?.subject as SubjectID) || SubjectID.FOOTBALL,
                assetsBaseHref: (cookieContext?.assetsBaseHref as string) || 'https://vp.cdn.pxr.nl',
                jwplayerKey: (cookieContext?.platform.jwplayerKey as string) || '',
            },
            locale: (cookieContext?.locale as LocaleID) || LocaleID.NL_NL,
            hostname: origin,
            isPlatformContext: !!cookieContext?.isPlatformContext || false,
        },
        config: {
            api: apiConfig,
            viapi: viapiConfig,
            viSocket: viSocketConfig,
            sportmonksF1: sportmonksF1Config,
            crossPlatform: crossPlatformConfig,
            captchaConfig: captchaConfig,
        },
    };

    if (contextData.context.domain.platform === 'vp' && contextData.context.domain.domainID === 2) {
        contextData.config.api.host = contextData.config.api.host.replace('.nl', '.be');
    }

    // FIXME: https://pxr-tech.atlassian.net/browse/PB-4086
    if (getPlatformIdByHost(origin) === PlatformID.VI) {
        contextData.context.domain.platform = PlatformID.VI;
        contextData.context.domain.domainID = 200;
        contextData.context.platform = {
            id: PlatformID.VI,
            name: 'VI',
            assetsBaseHref: 'https://vi.cdn.pxr.nl',
            subject: SubjectID.FOOTBALL,
            jwplayerKey: '',
        };
    }

    if (context) {
        newrelic.addCustomAttribute('platform', contextData.context.domain.platform);
        newrelic.addCustomAttribute('domainID', contextData.context.domain.domainID);
        newrelic.addCustomAttribute('hostname', contextData.context.hostname);
        newrelic.addCustomAttribute('path', context.resolvedUrl);

        for (const [key, value] of Object.entries(context.query)) {
            if (typeof value !== 'undefined') {
                newrelic.addCustomAttribute(key, value.toString());
            }
        }
    }

    return contextData;
};
