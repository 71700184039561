import newrelic from 'newrelic';
import { ApiError } from '@common/clients/vi/core/ApiError';
import { dot } from 'dot-object';

type OnErrorMethod = (err: Error) => void;

class NotFound extends ApiError {
    constructor({ request, url, status, statusText, body, message }: ApiError) {
        super(request, { url, status, statusText, body, ok: false }, `${message} - ${body?.message}`);
        this.name = 'NotFoundError';
    }
}

export const generateCatch = <T>(defaultValue: T, onError: OnErrorMethod | undefined = undefined) => {
    return (err: any): T => {
        if (err instanceof ApiError) {
            console.error(err);

            // Log error to newrelic
            if (typeof window === 'undefined' || window.navigator.appVersion === 'undefined') {
                const attributes = dot(
                    JSON.parse(JSON.stringify({ request: err.request, 'request.fullUrl': err.url })),
                );

                if (err?.status === 404) {
                    newrelic.noticeError(new NotFound(err), attributes, true);
                } else {
                    newrelic.noticeError(err, attributes);
                }
            }

            if (onError) {
                onError(err);
            }
        }
        return defaultValue;
    };
};

export const defaultCatch = generateCatch(undefined);
export const defaultArrayCatch = generateCatch([]);
