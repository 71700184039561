/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum NewsStatus {
    PUBLISHED = 'published',
    SCHEDULED = 'scheduled',
    DRAFT = 'draft',
}
