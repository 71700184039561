import { Translate } from 'next-translate';
import { ParsedUrlQueryInput } from 'node:querystring';

import { Domain } from '@common/defaults/Domain';
import { Route } from '@web/routing';

import { formatInternalRoute } from './formatInternalRoute';

export function formatRoute(
    domain: Domain,
    route: Route,
    routePrefix: string,
    __t: Translate,
    query?: ParsedUrlQueryInput,
): string {
    const path = formatInternalRoute(route, routePrefix, __t, query);
    return `https://${domain.domainUrl}${path}`;
}
