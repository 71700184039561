/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum WarmUpCacheType {
    WEBPWIDTH = 'webpwidth',
    THUMBNAIL = 'thumbnail',
    BACKGROUND = 'background',
}
