import { isArray } from 'lodash';
import { Translate } from 'next-translate';
import { ParsedUrlQueryInput } from 'node:querystring';

import { Route } from '@web/routing';

const translatableRegex = /^[a-z0-9-]+$/i;
export const formatInternalRoute = (
    route: Route,
    routePrefix: string,
    __t: Translate,
    query?: ParsedUrlQueryInput,
): string => {
    let path: string = route[0] === '/' ? route.substring(1) : route;

    // Translate pieces
    path = path
        .split('/')
        .map((piece) => (translatableRegex.test(piece) ? __t(`url:${piece}`) : piece))
        .join('/');

    // Add routePrefix
    path = path || !routePrefix ? `${routePrefix}/${path}` : `${routePrefix}`; // Add slash before routePrefix

    if (query) {
        for (let [key, value] of Object.entries(query)) {
            // Adjust the regular expression to include the correct brackets
            if (typeof value !== 'string' && isArray(value)) value = value.join('/');
            path = path.replace(new RegExp(`\\[\\[?\\.?\\.?\\.?${key}\\]?\\]`, 'g'), String(value));
        }
    }

    // remove optional catch-all routes, e.g. [[...slug]]
    path = path.replace(/\/\[\[\.\.\..+?\]\]/, '');

    return path;
};
