/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SportDataCompetition } from './SportDataCompetition';
import type { SportDataTeam } from './SportDataTeam';

export type SportDataPlayerClubTeam = {
    team: SportDataTeam;
    activeSince?: number;
    competition?: SportDataCompetition;
    position: SportDataPlayerClubTeam.position;
    shirtNumber?: number;
};

export namespace SportDataPlayerClubTeam {

    export enum position {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
    }


}

