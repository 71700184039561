import { FC } from 'react';
import Head from 'next/head';

export interface Props {
    data: any;
    entity: string;
    pageTitle?: string;
}
export const StructuredData: FC<Props> = ({ data, entity, pageTitle }) => {
    return (
        <Head>
            {pageTitle && <title>{pageTitle}</title>}
            <script
                key={entity}
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
            />
        </Head>
    );
};
