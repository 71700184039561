/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VideofeedConfig } from '../models/VideofeedConfig';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VideofeedConfigService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get videofeed config by contextID
     * Return a videofeed config for a given contextID
     * @returns VideofeedConfig Success
     * @throws ApiError
     */
    public getVideofeedConfigByDomainId({
        contextId,
        cache,
    }: {
        contextId: number,
        cache?: boolean,
    }): CancelablePromise<VideofeedConfig> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/videofeed-config',
            path: {
                'contextID': contextId,
            },
            query: {
                'cache': cache,
            },
        });
    }

    /**
     * Create or update videofeed config by contextID
     * Creates/updates and returns a videofeed config for a given contextID
     * @returns VideofeedConfig Success
     * @throws ApiError
     */
    public upsertVideofeedConfigByDomainId({
        contextId,
        requestBody,
    }: {
        contextId: number,
        requestBody: VideofeedConfig,
    }): CancelablePromise<VideofeedConfig> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/domain/{contextID}/videofeed-config',
            path: {
                'contextID': contextId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
