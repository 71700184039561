/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Comment } from '../models/Comment';
import type { CommentActionCreate } from '../models/CommentActionCreate';
import type { CommentActionPatch } from '../models/CommentActionPatch';
import type { CommentFull } from '../models/CommentFull';
import type { CommentOrderByOption } from '../models/CommentOrderByOption';
import type { CommentScopePublic } from '../models/CommentScopePublic';
import type { CommentStatusFilter } from '../models/CommentStatusFilter';
import type { CommentUpdateList } from '../models/CommentUpdateList';
import type { CommentUpdateListResponse } from '../models/CommentUpdateListResponse';
import type { Order } from '../models/Order';
import type { Pagination } from '../models/Pagination';
import type { PlatformID } from '../models/PlatformID';
import type { Scope } from '../models/Scope';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CommentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all comments
     * Return a list of comments for a given contextID.
     * @returns any Success
     * @throws ApiError
     */
    public listCommentByDomainId({
        contextId,
        scope,
        status,
        newsId,
        startDateTime,
        endDateTime,
        nested,
        orderBy,
        order,
        page,
        perPage,
        cache,
    }: {
        /**
         * will return items with contextID null or the current contextID
         */
        contextId: number,
        scope?: Scope,
        status?: CommentStatusFilter,
        newsId?: number,
        startDateTime?: string,
        endDateTime?: string,
        nested?: boolean,
        orderBy?: CommentOrderByOption,
        order?: Order,
        /**
         * current page
         */
        page?: number,
        /**
         * amount of items per page
         */
        perPage?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<CommentFull>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/comment',
            path: {
                'contextID': contextId,
            },
            query: {
                'scope': scope,
                'status': status,
                'newsID': newsId,
                'startDateTime': startDateTime,
                'endDateTime': endDateTime,
                'nested': nested,
                'orderBy': orderBy,
                'order': order,
                'page': page,
                'perPage': perPage,
                'cache': cache,
            },
        });
    }

    /**
     * Create a comment
     * Create a single comment and respond with the newly created item.
     * @returns CommentScopePublic Created
     * @throws ApiError
     */
    public createCommentByDomainId({
        contextId,
        requestBody,
    }: {
        contextId: number,
        requestBody: CommentActionCreate,
    }): CancelablePromise<CommentScopePublic> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/comment',
            path: {
                'contextID': contextId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Perform an update action on the list of comments
     * Update multiple comments
     * @returns CommentUpdateListResponse Success
     * @throws ApiError
     */
    public updateMultipleCommentByDomainId({
        contextId,
        requestBody,
        cache,
    }: {
        contextId: number,
        requestBody: CommentUpdateList,
        cache?: boolean,
    }): CancelablePromise<CommentUpdateListResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/comment',
            path: {
                'contextID': contextId,
            },
            query: {
                'cache': cache,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get most liked comments
     * Return a list of most liked comments for a given contextID
     * @returns Comment Success
     * @throws ApiError
     */
    public getMostLikesCommentByDomainId({
        contextId,
        cache,
    }: {
        /**
         * will return items with contextID null or the current contextID
         */
        contextId: number,
        cache?: boolean,
    }): CancelablePromise<Array<Comment>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/comment/most-likes',
            path: {
                'contextID': contextId,
            },
            query: {
                'cache': cache,
            },
        });
    }

    /**
     * Patch a comment
     * Patch a single comment
     * @returns CommentFull Success
     * @throws ApiError
     */
    public patchCommentByDomainId({
        contextId,
        id,
        scope,
        requestBody,
    }: {
        contextId: number,
        id: number,
        scope: Scope,
        requestBody: CommentActionPatch,
    }): CancelablePromise<CommentFull> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/comment/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            query: {
                'scope': scope,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a comment
     * Deletes and returns 204 or 404 depending if the request succeeds
     * @returns void
     * @throws ApiError
     */
    public deleteCommentByDomainId({
        contextId,
        id,
    }: {
        contextId: number,
        /**
         * Will be used to remove a comment
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/comment/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
        });
    }

    /**
     * Get all comments
     * Return a list of comments for a given contextID.
     * @returns any Success
     * @throws ApiError
     */
    public listCommentByPlatform({
        platform,
        scope,
        status,
        newsId,
        startDateTime,
        endDateTime,
        nested,
        orderBy,
        order,
        page,
        perPage,
        cache,
    }: {
        /**
         * will return items for a specific platform
         */
        platform: PlatformID,
        scope?: Scope,
        status?: CommentStatusFilter,
        newsId?: number,
        startDateTime?: string,
        endDateTime?: string,
        nested?: boolean,
        orderBy?: CommentOrderByOption,
        order?: Order,
        /**
         * current page
         */
        page?: number,
        /**
         * amount of items per page
         */
        perPage?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<CommentFull>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/comment',
            path: {
                'platform': platform,
            },
            query: {
                'scope': scope,
                'status': status,
                'newsID': newsId,
                'startDateTime': startDateTime,
                'endDateTime': endDateTime,
                'nested': nested,
                'orderBy': orderBy,
                'order': order,
                'page': page,
                'perPage': perPage,
                'cache': cache,
            },
        });
    }

    /**
     * Create a comment
     * Create a single comment and respond with the newly created item.
     * @returns CommentScopePublic Created
     * @throws ApiError
     */
    public createCommentByPlatform({
        platform,
        requestBody,
    }: {
        /**
         * will return items for a specific platform
         */
        platform: PlatformID,
        requestBody: CommentActionCreate,
    }): CancelablePromise<CommentScopePublic> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/platform/{platform}/comment',
            path: {
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Perform an update action on the list of comments
     * Update multiple comments
     * @returns CommentUpdateListResponse Success
     * @throws ApiError
     */
    public updateMultipleCommentByPlatform({
        platform,
        requestBody,
        cache,
    }: {
        /**
         * will return items for a specific platform
         */
        platform: PlatformID,
        requestBody: CommentUpdateList,
        cache?: boolean,
    }): CancelablePromise<CommentUpdateListResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/platform/{platform}/comment',
            path: {
                'platform': platform,
            },
            query: {
                'cache': cache,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get most liked comments
     * Return a list of most liked comments for a given contextID
     * @returns Comment Success
     * @throws ApiError
     */
    public getMostLikesCommentByPlatform({
        platform,
        cache,
    }: {
        /**
         * will return items for a specific platform
         */
        platform: PlatformID,
        cache?: boolean,
    }): CancelablePromise<Array<Comment>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/comment/most-likes',
            path: {
                'platform': platform,
            },
            query: {
                'cache': cache,
            },
        });
    }

    /**
     * Patch a comment
     * Patch a single comment
     * @returns CommentFull Success
     * @throws ApiError
     */
    public patchCommentByPlatform({
        platform,
        id,
        scope,
        requestBody,
    }: {
        /**
         * will return items for a specific platform
         */
        platform: PlatformID,
        id: number,
        scope: Scope,
        requestBody: CommentActionPatch,
    }): CancelablePromise<CommentFull> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/platform/{platform}/comment/{id}',
            path: {
                'platform': platform,
                'id': id,
            },
            query: {
                'scope': scope,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a comment
     * Deletes and returns 204 or 404 depending if the request succeeds
     * @returns void
     * @throws ApiError
     */
    public deleteCommentByPlatform({
        platform,
        id,
    }: {
        platform: PlatformID,
        /**
         * Will be used to remove a comment
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/platform/{platform}/comment/{id}',
            path: {
                'platform': platform,
                'id': id,
            },
        });
    }

}
