/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum GoalType {
    G = 0,
    OG = 1,
    PG = 2,
    NG = 3,
}
