/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Notification } from '../models/Notification';
import type { NotificationUpdateList } from '../models/NotificationUpdateList';
import type { NotificationUpdateListResponse } from '../models/NotificationUpdateListResponse';
import type { Pagination } from '../models/Pagination';
import type { PlatformID } from '../models/PlatformID';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NotificationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all notifications for a user
     * Return a list of comments for a given contextID.
     * @returns any Success
     * @throws ApiError
     */
    public listNotificationByDomainId({
        contextId,
        userId,
        page = 1,
        perPage = 100,
    }: {
        /**
         * will return items with contextID null or the current contextID
         */
        contextId: number,
        /**
         * ID of the user
         */
        userId: number,
        /**
         * current page
         */
        page?: number,
        /**
         * amount of items per page
         */
        perPage?: number,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<Notification>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/notification',
            path: {
                'contextID': contextId,
            },
            query: {
                'userID': userId,
                'page': page,
                'perPage': perPage,
            },
        });
    }

    /**
     * Marks all Notifications for a user as read
     * @returns string Success
     * @throws ApiError
     */
    public markAllAsReadNotificationByDomainId({
        contextId,
        userId,
    }: {
        /**
         * will return items with contextID null or the current contextID
         */
        contextId: number,
        /**
         * ID of the user
         */
        userId: number,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/notification/mark-as-read',
            path: {
                'contextID': contextId,
            },
            query: {
                'userID': userId,
            },
        });
    }

    /**
     * Marks a given Notification as read
     * @returns Notification Success
     * @throws ApiError
     */
    public markAsReadNotificationByDomainId({
        contextId,
        id,
    }: {
        /**
         * will return items with contextID null or the current contextID
         */
        contextId: number,
        /**
         * ID of the notification
         */
        id: number,
    }): CancelablePromise<Notification> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/notification/{id}/mark-as-read',
            path: {
                'contextID': contextId,
                'id': id,
            },
        });
    }

    /**
     * Marks a list of Notifications as read
     * @returns NotificationUpdateListResponse Success
     * @throws ApiError
     */
    public markSelectedAsReadNotificationByDomainId({
        contextId,
        requestBody,
    }: {
        /**
         * will return items with contextID null or the current contextID
         */
        contextId: number,
        requestBody: NotificationUpdateList,
    }): CancelablePromise<NotificationUpdateListResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/notification/mark-selected-as-read',
            path: {
                'contextID': contextId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the number of unread notifications for an user
     * @returns number Success
     * @throws ApiError
     */
    public getUnreadNotificationsCountNotificationByDomainId({
        contextId,
        userId,
    }: {
        /**
         * will return items with contextID null or the current contextID
         */
        contextId: number,
        /**
         * ID of the user
         */
        userId: number,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/notification/unread-count',
            path: {
                'contextID': contextId,
            },
            query: {
                'userID': userId,
            },
        });
    }

    /**
     * Get all notifications for a user
     * Return a list of comments for a given contextID.
     * @returns any Success
     * @throws ApiError
     */
    public listNotificationByPlatform({
        platform,
        userId,
        page = 1,
        perPage = 100,
    }: {
        /**
         * will return items for a specific platform
         */
        platform: PlatformID,
        /**
         * ID of the user
         */
        userId: number,
        /**
         * current page
         */
        page?: number,
        /**
         * amount of items per page
         */
        perPage?: number,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<Notification>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/notification',
            path: {
                'platform': platform,
            },
            query: {
                'userID': userId,
                'page': page,
                'perPage': perPage,
            },
        });
    }

    /**
     * Marks all Notifications for a user as read
     * @returns string Success
     * @throws ApiError
     */
    public markAllAsReadNotificationByPlatform({
        platform,
        userId,
    }: {
        /**
         * will return items for a specific platform
         */
        platform: PlatformID,
        /**
         * ID of the user
         */
        userId: number,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/platform/{platform}/notification/mark-as-read',
            path: {
                'platform': platform,
            },
            query: {
                'userID': userId,
            },
        });
    }

    /**
     * Marks a given Notification as read
     * @returns Notification Success
     * @throws ApiError
     */
    public markAsReadNotificationByPlatform({
        platform,
        id,
    }: {
        /**
         * will return items for a specific platform
         */
        platform: PlatformID,
        /**
         * ID of the notification
         */
        id: number,
    }): CancelablePromise<Notification> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/platform/{platform}/notification/{id}/mark-as-read',
            path: {
                'platform': platform,
                'id': id,
            },
        });
    }

    /**
     * Marks a list of Notifications as read
     * @returns NotificationUpdateListResponse Success
     * @throws ApiError
     */
    public markSelectedAsReadNotificationByPlatform({
        platform,
        requestBody,
    }: {
        /**
         * will return items for a specific platform
         */
        platform: PlatformID,
        requestBody: NotificationUpdateList,
    }): CancelablePromise<NotificationUpdateListResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/platform/{platform}/notification/mark-selected-as-read',
            path: {
                'platform': platform,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the number of unread notifications for an user
     * @returns number Success
     * @throws ApiError
     */
    public getUnreadNotificationsCountNotificationByPlatform({
        platform,
        userId,
    }: {
        /**
         * will return items for a specific platform
         */
        platform: PlatformID,
        /**
         * ID of the user
         */
        userId: number,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/notification/unread-count',
            path: {
                'platform': platform,
            },
            query: {
                'userID': userId,
            },
        });
    }

}
