/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SportDataPlayerStats } from './SportDataPlayerStats';
import type { Tag } from './Tag';

export type SportDataPlayer = {
    teamName?: string;
    weight?: string;
    type?: string;
    birthDate?: number;
    nationality?: string;
    stats?: SportDataPlayerStats;
    id: string;
    teamId?: string;
    countryCodeIsoAlpha2?: string;
    name: string;
    shirtNumber?: number;
    countryCode?: string;
    position?: SportDataPlayer.position;
    height?: string;
    tag?: Tag;
};

export namespace SportDataPlayer {

    export enum position {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
    }


}

