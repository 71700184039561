/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ModuleScheduleStatus {
    ACTIVE = 'active',
    EXPIRED = 'expired',
    FUTURE = 'future',
}
