export enum Route {
    Homepage = '',

    // News
    Article = 'news/[...slug]',
    CategoryNews = 'category-news/[[...slug]]',
    TransferNews = 'news/transfernews',
    TransferRumors = 'transferrumors',
    VideoArticle = 'videos/[slug]/[articleSlug]',
    VideoCategory = 'videos/[slug]',
    VideoOverview = 'videos',

    // Tag Overview
    BrandOverview = 'brands',
    CarOverview = 'cars',
    CircuitOverview = 'circuits',
    ClubOverview = 'clubs',
    CompetitionOverview = 'competitions',
    DossierOverview = 'dossiers',
    DriverOverview = 'f1-drivers',
    F1Calendar = 'f1-calendar',
    GrandsprixOverview = 'grandsprix',
    LocationOverview = 'locations',
    OrganizationOverview = 'organization',
    PersonOverview = 'people',
    PlayerOverview = 'players',
    RaceClassOverview = 'race-classes',
    TeamBossOverview = 'team-bosses',
    TeamOverview = 'f1-teams',
    TopicOverview = 'topics',

    // Tag Detail
    Betting = 'dossiers/wedden',
    Brand = 'brands/[tag]',
    Car = 'cars/[tag]',
    Circuit = 'circuits/[tag]',
    Club = 'clubs/[tag]',
    Competition = 'competitions/[tag]',
    Dossier = 'dossiers/[tag]',
    Driver = 'f1-drivers/[tag]',
    Grandsprix = 'grandsprix/[tag]',
    Location = 'locations/[tag]',
    Organization = 'organization/[tag]',
    Person = 'people/[tag]',
    Player = 'players/[tag]',
    RaceClass = 'race-classes/[tag]',
    Team = 'f1-teams/[tag]',
    TeamBoss = 'team-bosses/[tag]',
    Topic = 'topics/[tag]',

    // Sport Data
    Match = 'livescores/match/[matchID]',
    MatchOverview = 'livescores',
    Standing = 'standing/[[...slug]]',

    // Profile
    ForgotPassword = 'password-forgot/[[...slug]]',
    Login = 'login',
    Logout = 'logout',
    Notifications = 'profile/notifications',
    Profile = 'profile',
    Register = 'register',
    Settings = 'profile/settings',

    // Sitemaps
    SitemapIndex = 'sitemap.xml',
    NavigationSitemap = 'sitemap-navigation.xml',
    GoogleNewsSitemap = 'sitemap-google-news.xml',
    SegmentedNewsSitemap = 'sitemap/news/[year]/[week]',
    SegmentedVideosSitemap = 'sitemap/videos/[year]/[month]',
    SegmentedTagSitemap = 'sitemap/[tagType]/[from]/[to]',
    TagSitemap = 'sitemap/[tagType]',

    // RSS
    RssOverview = 'rss-feeds',
    Rss = 'rss',

    // Others
    Liveness = 'liveness',
    Page = 'page/[slug]',
    Sandbox = 'sandbox',
    Search = 'search',
    ThuisFluiter = 'thuisfluiter',
    User = 'user/[slug]',
}
