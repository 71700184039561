/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TagTypeAsString {
    SOCCER_COMPETITION = 'SoccerCompetition',
    SOCCER_CLUB = 'SoccerClub',
    SOCCER_PLAYER = 'SoccerPlayer',
    SOCCER_COMPETITION_TOURNAMENT = 'SoccerCompetitionTournament',
    SOCCER_DOSSIER = 'SoccerDossier',
    GPDRIVER = 'GPDriver',
    GPTEAM = 'GPTeam',
    GPTEAM_BOSS = 'GPTeamBoss',
    GPGRAND_PRIX = 'GPGrandPrix',
    GPDOSSIER = 'GPDossier',
    GPRACE_CLASS = 'GPRaceClass',
    GPCIRCUIT = 'GPCircuit',
    GPBRAND = 'GPBrand',
    GPCAR = 'GPCar',
    TOPICS = 'Topics',
    PEOPLE = 'People',
    ORGANIZATIONS = 'Organizations',
    LOCATIONS = 'Locations',
}
