/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum StatType {
    TOTAL_SHOTS = 0,
    SHOTS_ON_GOAL = 1,
    FOULS = 2,
    CORNERS = 3,
    OFFSIDE = 4,
    POSSESSION = 5,
}
