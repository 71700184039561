/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewsCategory } from '../models/NewsCategory';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NewsCategoryService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get news categories
     * Return a list of news categories for a given contextID
     * @returns NewsCategory Success
     * @throws ApiError
     */
    public listNewsCategoryByDomainId({
        contextId,
        nameSlug,
        isMedia,
    }: {
        /**
         * will filter items by contextID
         */
        contextId: number,
        /**
         * will filter items by nameSlug
         */
        nameSlug?: string,
        /**
         * will filter newsCategories that are video categories or general categories
         */
        isMedia?: boolean,
    }): CancelablePromise<Array<NewsCategory>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/news-category',
            path: {
                'contextID': contextId,
            },
            query: {
                'nameSlug': nameSlug,
                'isMedia': isMedia,
            },
        });
    }

}
