/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Ad } from '../models/Ad';
import type { AdActionPatch } from '../models/AdActionPatch';
import type { AdPlacement } from '../models/AdPlacement';
import type { PlacementList } from '../models/PlacementList';
import type { Scope } from '../models/Scope';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all ads
     * @returns Ad Success
     * @throws ApiError
     */
    public listAdByDomainId({
        contextId,
        scope,
        cache,
    }: {
        contextId: number,
        scope?: Scope,
        cache?: boolean,
    }): CancelablePromise<Array<Ad>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/ad',
            path: {
                'contextID': contextId,
            },
            query: {
                'scope': scope,
                'cache': cache,
            },
        });
    }

    /**
     * Get all ads, grouped by placement
     * @returns PlacementList Success
     * @throws ApiError
     */
    public listGroupedByPlacementAdByDomainId({
        contextId,
        scope,
        cache,
    }: {
        contextId: number,
        scope?: Scope,
        cache?: boolean,
    }): CancelablePromise<PlacementList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/ad/grouped-by-placement',
            path: {
                'contextID': contextId,
            },
            query: {
                'scope': scope,
                'cache': cache,
            },
        });
    }

    /**
     * Upsert an ad placement
     * @returns Ad Success
     * @throws ApiError
     */
    public updateAdByDomainId({
        contextId,
        placement,
        requestBody,
    }: {
        contextId: number,
        placement: AdPlacement,
        requestBody: AdActionPatch,
    }): CancelablePromise<Ad> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/ad/{placement}',
            path: {
                'contextID': contextId,
                'placement': placement,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
