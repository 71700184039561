/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PlatformID {
    BR = 'br',
    GP = 'gp',
    VI = 'vi',
    VN = 'vn',
    VP = 'vp',
}
