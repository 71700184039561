/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EventType {
    GOAL = 1,
    SUBSTITUTION = 2,
    PENALTY_MISSED = 3,
    CARD = 4,
    STAGE = 5,
    SHOOTOUT_PENALTY = 6,
}
