/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ModuleScheduleSection {
    COMMENTS = 'comments',
    TAG_NEWS_LIST = 'tagNewsList',
    ARTICLE_MATCHES = 'articleMatches',
    BETTING_PAGE = 'bettingPage',
    ODDS_DOSSIER_HOME = 'oddsDossierHome',
    ODDS_POLLS = 'oddsPolls',
    ODDS_MATCH_TICKER = 'oddsMatchTicker',
    ODDS_MATCHES = 'oddsMatches',
    PROMO_ELEMENT_BELOW_ARTICLE = 'promoElementBelowArticle',
    PROMO_ELEMENT_BELOW_VIDEO_CAROUSEL = 'promoElementBelowVideoCarousel',
}
