import { LocaleID, PlatformID, SubjectID } from '@common/clients/api';
import { ContextData } from '@common/defaults';
import { createContext, useContext } from 'react';

export const NextContext = createContext<ContextData>({
    userID: 0,
    userSessionID: '',
    magnoliaSessionID: '',
    routePrefix: '',
    context: {
        domain: {
            domainID: 0,
            locale: LocaleID.EN,
            platform: PlatformID.VP,
            slug: '',
            domainUrl: '',
            twitter: '',
            facebook: '',
            instagram: '',
            tiktok: '',
            youtube: '',
            address: '',
        },
        hostname: '',
        locale: LocaleID.EN,
        platform: {
            id: PlatformID.VP,
            name: '',
            assetsBaseHref: '',
            subject: SubjectID.FOOTBALL,
            jwplayerKey: '',
        },
        isPlatformContext: false,
    },
    config: {
        api: {
            host: '',
            timeout: 0,
        },
        viapi: {
            host: '',
            timeout: 0,
        },
        viSocket: {
            host: '',
            timeout: 0,
        },
        sportmonksF1: {
            host: '',
            timeout: 0,
        },
        crossPlatform: {},
        captchaConfig: {
            siteKey: '',
        },
    },
});

export const useContextData = (): ContextData => {
    const contextData = useContext(NextContext);

    return contextData;
};
