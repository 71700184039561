/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Pagination } from '../models/Pagination';
import type { Poll } from '../models/Poll';
import type { PollActionCreate } from '../models/PollActionCreate';
import type { PollActionPatch } from '../models/PollActionPatch';
import type { PollAnswer } from '../models/PollAnswer';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PollService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get poll by id
     * Return a poll with a given pollID
     * @returns Poll Success
     * @throws ApiError
     */
    public getPollByIdPollByDomainId({
        contextId,
        id,
        includeAnswers,
    }: {
        /**
         * will filter items by contextID
         */
        contextId: number,
        /**
         * will filter items by pollID
         */
        id: number,
        includeAnswers?: boolean,
    }): CancelablePromise<Poll> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/poll/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            query: {
                'includeAnswers': includeAnswers,
            },
        });
    }

    /**
     * Patch poll
     * @returns Poll Success
     * @throws ApiError
     */
    public patchPollByDomainId({
        contextId,
        id,
        requestBody,
    }: {
        /**
         * will filter items by contextID
         */
        contextId: number,
        id: number,
        requestBody: PollActionPatch,
    }): CancelablePromise<Poll> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/domain/{contextID}/poll/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete an existing poll with its answers
     * Deletes and returns 204 if the request succeeds
     * @returns void
     * @throws ApiError
     */
    public deletePollByDomainId({
        contextId,
        id,
    }: {
        contextId: number,
        /**
         * will be used to remove an existing PollQuestion item
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/poll/{id}',
            path: {
                'contextID': contextId,
                'id': id,
            },
        });
    }

    /**
     * Get polls
     * Return a list of polls for a given contextID
     * @returns any Success
     * @throws ApiError
     */
    public listPollByDomainId({
        contextId,
        search,
        newsId,
        matchId,
        includeAnswers,
        page,
        perPage = 20,
    }: {
        /**
         * will filter items by contextID
         */
        contextId: number,
        /**
         * will fuzzy search in the pollQuestion column
         */
        search?: string,
        /**
         * will filter items by newsID
         */
        newsId?: number,
        /**
         * will filter items by matchID
         */
        matchId?: string,
        includeAnswers?: boolean,
        page?: number,
        perPage?: number,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<Poll>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/poll',
            path: {
                'contextID': contextId,
            },
            query: {
                'search': search,
                'newsID': newsId,
                'matchID': matchId,
                'includeAnswers': includeAnswers,
                'page': page,
                'perPage': perPage,
            },
        });
    }

    /**
     * Create a poll
     * Create poll including answers and respond with the newly created item.
     * @returns Poll Created
     * @throws ApiError
     */
    public createPollByDomainId({
        contextId,
        requestBody,
    }: {
        /**
         * will filter items by contextID
         */
        contextId: number,
        requestBody: PollActionCreate,
    }): CancelablePromise<Poll> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/poll',
            path: {
                'contextID': contextId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Vote for a poll
     * @returns boolean Success
     * @throws ApiError
     */
    public votePollByDomainId({
        contextId,
        pollId,
        answerId,
    }: {
        /**
         * will filter items by contextID
         */
        contextId: number,
        pollId: number,
        answerId: number,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/domain/{contextID}/poll/{pollID}/answer/{answerID}/vote',
            path: {
                'contextID': contextId,
                'pollID': pollId,
                'answerID': answerId,
            },
        });
    }

    /**
     * returns answer that has been voted by user’s IP
     * @returns PollAnswer Success
     * @throws ApiError
     */
    public getVotedAnswerIdPollByDomainId({
        contextId,
        pollId,
    }: {
        /**
         * will filter items by contextID
         */
        contextId: number,
        pollId: number,
    }): CancelablePromise<PollAnswer> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/poll/{pollID}/voted-answer',
            path: {
                'contextID': contextId,
                'pollID': pollId,
            },
        });
    }

}
