/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Order } from '../models/Order';
import type { Pagination } from '../models/Pagination';
import type { Scope } from '../models/Scope';
import type { Tag } from '../models/Tag';
import type { TagType } from '../models/TagType';
import type { TagTypeAsString } from '../models/TagTypeAsString';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TagService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get a list of tags.
     * Return a list of tags. ContextID will be used to localize tag data.
     * @returns any Success
     * @throws ApiError
     */
    public listTagByDomainId({
        contextId,
        title,
        optaIDs,
        sportmonksIDs,
        sportmonksTagTypes,
        isCompactMode,
        checkNextPage,
        cache,
        tagType,
        tagTypeId,
        isTrending,
        showOverview,
        showHidden,
        showOnRssIndex,
        orderBy,
        order,
        scope,
        page = 1,
        perPage = 20,
    }: {
        contextId: number,
        /**
         * Filter by the name of the tag
         */
        title?: string,
        /**
         * Filter tags by multiple optaIDs. Sorting the IDs and filtering duplicates will likely improve cache hit ratio and thus performance.
         */
        optaIDs?: Array<string>,
        /**
         * Filter tags by a list of sportmonksIDs. Every sportmonkID corresponds to a tagType with the same index in the sportmonksTagTypes list. The lists of sportmonksIDs and sportmonksTagTypes must have the same length.
         */
        sportmonksIDs?: Array<number>,
        /**
         * Every tagType here corresponds to an id with the same index in sportmonksIDs list.
         */
        sportmonksTagTypes?: Array<TagTypeAsString>,
        /**
         * Do not include large properties like tagDescription and tagOutro
         */
        isCompactMode?: boolean,
        /**
         * Do not count the total number of items. Only check if there is a next page.
         */
        checkNextPage?: boolean,
        cache?: boolean,
        /**
         * Filter by the type of the tag. If scope='public' (the default value) and tagType='SoccerCompetition'|'SoccerCompetitionTournament', the tags with tagType='SoccerCompetition' and the tags with tagType='SoccerCompetitionTournament' will be returned.
         */
        tagType?: TagTypeAsString,
        /**
         * Use 'tagType' instead. Filter by the tag type id of the tag. If scope='public' (the default value) and tagTypeID=1|4, the tags with tagTypeID=1 and the tags with tagTypeID=4 will be returned.
         * @deprecated
         */
        tagTypeId?: number,
        /**
         * Filter by if the tag is trending
         */
        isTrending?: boolean,
        /**
         * Filter by if the tag is a overview
         */
        showOverview?: boolean,
        /**
         * Show hidden tags
         */
        showHidden?: boolean,
        /**
         * Filter by if the tag is shown on Rss page
         */
        showOnRssIndex?: boolean,
        /**
         * will return items ordered by a given column
         */
        orderBy?: string,
        /**
         * order results in either ascending or descending order
         */
        order?: Order,
        scope?: Scope,
        page?: number,
        perPage?: number,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<Tag>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/tag',
            path: {
                'contextID': contextId,
            },
            query: {
                'title': title,
                'optaIDs': optaIDs,
                'sportmonksIDs': sportmonksIDs,
                'sportmonksTagTypes': sportmonksTagTypes,
                'isCompactMode': isCompactMode,
                'checkNextPage': checkNextPage,
                'cache': cache,
                'tagType': tagType,
                'tagTypeID': tagTypeId,
                'isTrending': isTrending,
                'showOverview': showOverview,
                'showHidden': showHidden,
                'showOnRssIndex': showOnRssIndex,
                'orderBy': orderBy,
                'order': order,
                'scope': scope,
                'page': page,
                'perPage': perPage,
            },
        });
    }

    /**
     * Get tag types by context.
     * @returns any Success
     * @throws ApiError
     */
    public getTagTypesTagByDomainId({
        contextId,
    }: {
        contextId: number,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<TagType>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/tag/types',
            path: {
                'contextID': contextId,
            },
        });
    }

    /**
     * Get tag by slug.
     * Get tag by slug. ContextID will be used to localize tag data.
     * @returns Tag Success
     * @throws ApiError
     */
    public getBySlugTagByDomainId({
        contextId,
        slug,
        cache,
    }: {
        contextId: number,
        /**
         * Get tag by slug
         */
        slug: string,
        cache?: boolean,
    }): CancelablePromise<Tag> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/tag/{slug}',
            path: {
                'contextID': contextId,
                'slug': slug,
            },
            query: {
                'cache': cache,
            },
        });
    }

    /**
     * Delete tags
     * Deletes and returns 204 or 404 depending if the request succeeds
     * @returns void
     * @throws ApiError
     */
    public deleteTagByDomainId({
        contextId,
        tagId,
    }: {
        contextId: number,
        /**
         * will be used to remove an existing tag item
         */
        tagId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/domain/{contextID}/tag/{tagID}',
            path: {
                'contextID': contextId,
                'tagID': tagId,
            },
        });
    }

}
