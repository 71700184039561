import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import type { Organization, WithContext } from 'schema-dts';

import { useContextData } from '@common/useContextData';
import { formatRoute, Route } from '@web/routing';

import { StructuredData } from './atoms/StructuredData';

export const OrganizationStructuredData: FC = () => {
    const contextData = useContextData();
    const { routePrefix, context } = contextData;
    const { domain, platform } = context;
    const __url = useTranslation('url').t;
    const path = formatRoute(domain, Route.Homepage, routePrefix, __url);
    const data: WithContext<Organization> = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        '@id': path,
        name: platform.name || '',
        url: path,
        logo: {
            '@type': 'ImageObject',
            url: `${platform.assetsBaseHref}images/${platform.id}/logo-dark.svg`,
        },
        location: {
            '@type': 'Place',
            name: platform.name || '',
            address: domain.address || '',
        },
    };

    const sameAs: string[] = [];
    if (domain.twitter) sameAs.push('https://twitter.com/' + domain.twitter);
    if (domain.facebook) sameAs.push('https://facebook.com/' + domain.facebook);
    if (domain.instagram) sameAs.push('https://instagram.com/' + domain.instagram);
    if (domain.tiktok) sameAs.push('https://tiktok.com/' + domain.tiktok);
    if (domain.youtube) sameAs.push('https://youtube.com/' + domain.youtube);
    if (sameAs) data.sameAs = sameAs;

    return <StructuredData data={data} entity={'Organization'} />;
};
