
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useEffect, useMemo } from 'react';
import newrelic from 'newrelic';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'next-themes';
import { ContextData, getPlatformIdByHost } from '@common/defaults';
import { AdsByPlacement } from '@common/types';
import { NextContext } from '@common/useContextData';
import { SidebarHolder } from '@web/molecules/Sidebar';
import { OrganizationStructuredData } from '@web/molecules/StructuredData';
import '../styles/index.scss';
declare global {
    interface Window {
        gtag: Gtag.Gtag;
    }
}
function MyApp({ Component, pageProps, router }: AppProps) {
    const props: any = { ...pageProps };
    let origin = props?.context?.hostname ?? '';
    // FIXME: avoid router to handle non-shallow routes
    const nextRouter = useRouter();
    useEffect(() => {
        const handleRouteChange = (url: string, options: any) => {
            if (options?.shallow === false) {
                window.location.pathname = url;
            }
        };
        nextRouter.events.on('routeChangeStart', handleRouteChange);
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [nextRouter.events, router.events]);
    if (typeof window === 'undefined' || window.navigator.appVersion === 'undefined') {
        newrelic.setTransactionName(router.pathname ?? '*');
    }
    else if (!origin || origin.length) {
        origin = window?.location?.hostname ?? '';
    }
    const platformID = getPlatformIdByHost(origin);
    const theme = useMemo(() => {
        if (/voetbalprimeur.be|voetbalprimeurbe|\bvp-?be\b/.test(origin))
            return 'vpbe';
        else if (platformID)
            return platformID;
        else if (process.env.NEXT_PUBLIC_THEME)
            return process.env.NEXT_PUBLIC_THEME;
        else if (props?.context?.platform?.id)
            return props?.context?.platform?.id;
        else if (process.env.APP_ENV == 'development' || process.env.CI == 'true')
            return 'vp';
        else {
            console.warn(`Theme was not found for origin '${origin}'. Defaulting to 'vp'.`);
            return 'vp';
        }
    }, [origin, platformID, props?.context?.platform?.id]);
    // When ads are not fetched by the getServerSideProps, make sure we still fetch them
    const ads = useMemo<AdsByPlacement>(() => {
        if (props.errorCode || !props?.config?.api)
            return {};
        if (props.ads)
            return props.ads;
        return import('@web/handlers/fetchAds').then((mod) => mod.fetchAds(props, nextRouter.query, typeof window !== 'undefined'));
    }, [platformID]); // eslint-disable-line react-hooks/exhaustive-deps
    const contextData: ContextData = {
        config: props.config,
        context: props.context,
        magnoliaSessionID: props.magnoliaSessionID,
        userID: props.userID,
        userSessionID: props.userSessionID,
        ads: ads,
        routePrefix: props.routePrefix
    };
    const showClientSidebar = !pageProps?.sidebarProps && typeof contextData?.context !== 'undefined';
    // Avoid next.voetbalprimeur.nl and next.staging.voetbalprimeur.nl to be crawled
    const isCrawlable = /www\./.test(contextData.context?.domain?.domainUrl);
    return (<ThemeProvider themes={['vp', 'vpbe', 'vn', 'gp']} forcedTheme={theme}>
            <NextContext.Provider value={contextData}>
                <Head>
                    {isCrawlable ? (<meta name="robots" content="max-image-preview:large"/>) : (<meta name="robots" content="noindex, nofollow"/>)}
                </Head>
                {contextData?.context ? <OrganizationStructuredData /> : null}
                <Component {...pageProps}/>
                {showClientSidebar ? <SidebarHolder /> : null}
            </NextContext.Provider>
        </ThemeProvider>);
}
export function reportWebVitals(metric: NextWebVitalsMetric) {
    console.log(metric);
    if (typeof global.window.gtag !== 'undefined') {
        window.gtag('event', metric.name, {
            event_category: metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
            value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value),
            event_label: metric.id,
            non_interaction: true
        });
    }
}
const __Next_Translate__Page__18c89ee92ca__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__18c89ee92ca__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  