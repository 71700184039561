/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ExperimentGroupOrRobots {
    A = 'a',
    B = 'b',
    ROBOTS = 'robots',
}
