/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CompetitionType {
    UNKNOWN = 0,
    DOMESTIC_LEAGUE = 1,
    DOMESTIC_CUP = 2,
    DOMESTIC_SUPER_CUP = 3,
    INTERNATIONAL_CUP = 4,
}
