/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum NotificationType {
    NEWS_COMMENT = 'newsComment',
    NEWS_THUMBS = 'newsThumbs',
    FILTERED_COMMENT = 'filteredComment',
    USER_TEMP_BAN = 'userTempBan',
}
