/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ConfigKey {
    JWPLAYER_AD_SCHEDULE = 'jwplayer-ad-schedule',
    JWPLAYER_VAST_TAGS = 'jwplayer-vast-tags',
    JWPLAYER_IOS_VAST = 'jwplayer-ios-vast',
    JWPLAYER_ANDROID_VAST = 'jwplayer-android-vast',
    JWPLAYER_GTAG = 'jwplayer-gtag',
    JWPLAYER_XANDR = 'jwplayer-xandr',
    JWPLAYER_GPS = 'jwplayer-gps',
    G_RECAPTCHA_THRESHOLD = 'g-recaptcha-threshold',
    IP_QS = 'ip-qs',
}
