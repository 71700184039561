/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SubjectID {
    FOOTBALL = 'football',
    MOTORSPORT = 'motorsport',
    TECH = 'tech',
}
