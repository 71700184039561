const fileCache = {};

let tryCreatingMissingKey;
if (process && process.env?.LOKALISE_API_KEY) {
    tryCreatingMissingKey = require('./tryCreatingMissingKey.js');
}

const getLocaleFile = (locale, namespace) => {
    locale = locale.replace('-', '_');
    return `../i18n/locale/${locale}/${namespace}.json`;
};

const orderKeys = (unordered) => {
    return Object.keys(unordered)
        .sort()
        .reduce((obj, key) => {
            let value = unordered[key];
            if (typeof value == 'object') {
                value = orderKeys(value);
            }
            obj[key] = value;
            return obj;
        }, {});
};

const loadLocaleFrom = (locale, namespace) => {
    locale = locale.replace('-', '_');

    if (!fileCache[locale]) fileCache[locale] = {};
    if (!fileCache[locale][namespace]) {
        return import(`./../../locale/${locale}/${namespace}.json`)
            .then((file) => {
                const json = file.default;
                fileCache[locale][namespace] = json;
                return json;
            })
            .catch((err) => {
                console.warn(`[i18n] ${locale}/${namespace}.json is missing.`);
                return {};
            });
    }
    return new Promise(function (resolve, reject) {
        resolve(fileCache[locale][namespace]);
    });
};

const missingKeyLogger = ({ namespace, i18nKey }) => {
    if (process && process.env.NODE_ENV === 'production') return;

    // This means that instead of "ns:value", "value" has been misspelled (without namespace)
    if (!i18nKey) {
        console.warn(`[i18n] The text "${namespace}" has no namespace in front of it.`);
        return;
    }

    // Namespaces may not contain uppercase characters
    if (/[A-Z]/g.test(namespace)) {
        console.warn(
            `[i18n] Your "${namespace}" contains a uppercase character, please change to dashed-case.`,
        );
        return;
    }

    // // Try creating the missing key
    if (process && process.env?.LOKALISE_API_KEY) {
        tryCreatingMissingKey.tryCreatingMissingKey(namespace, i18nKey);
    }

    console.warn(
        `[i18n] "${namespace}:${i18nKey}" is missing in current namespace configuration. Please add "${i18nKey}" to the namespace "${namespace}".`,
    );
};

exports.getLocaleFile = getLocaleFile;
exports.loadLocaleFrom = loadLocaleFrom;
exports.missingKeyLogger = missingKeyLogger;
