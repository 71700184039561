/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Author } from '../models/Author';
import type { Pagination } from '../models/Pagination';
import type { User } from '../models/User';
import type { UserActionUpdate } from '../models/UserActionUpdate';
import type { UserSessionDeleteResult } from '../models/UserSessionDeleteResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get a list of ignored userIDs.
     * Return a list of ignored userIDs, filtered by the authenticated user.
     * @returns number Success
     * @throws ApiError
     */
    public listIgnoredUser({
        cache,
    }: {
        cache?: boolean,
    }): CancelablePromise<Array<number>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/ignore',
            query: {
                'cache': cache,
            },
        });
    }

    /**
     * Ignore multiple users by userIDs.
     * Add multiple userIDs to the list of ignored users.
     * @returns number Created
     * @throws ApiError
     */
    public addIgnoredUser({
        requestBody,
    }: {
        requestBody: Array<number>,
    }): CancelablePromise<Array<number>> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/user/ignore',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete an ignored user by userID
     * Delete a userID from the list of ignored users.
     * @returns void
     * @throws ApiError
     */
    public deleteIgnoredUser({
        userId,
    }: {
        /**
         * userID of the user that should be removed from the current user's ignored user list
         */
        userId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/ignore/{userID}',
            path: {
                'userID': userId,
            },
        });
    }

    /**
     * Get user list
     * Get a list of all users
     * @returns any Success
     * @throws ApiError
     */
    public listUser({
        search,
        page = 1,
        perPage = 20,
        cache,
    }: {
        search?: string,
        /**
         * current page
         */
        page?: number,
        /**
         * amount of items per page
         */
        perPage?: number,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<User>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user',
            query: {
                'search': search,
                'page': page,
                'perPage': perPage,
                'cache': cache,
            },
        });
    }

    /**
     * Get user-author by slug.
     * Get user-author by slug.
     * @returns Author Success
     * @throws ApiError
     */
    public getByCustomSlugUser({
        slug,
    }: {
        /**
         * Get user by customSlug
         */
        slug: string,
    }): CancelablePromise<Author> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/author/{slug}',
            path: {
                'slug': slug,
            },
        });
    }

    /**
     * Get user by id.
     * Get user by id.
     * @returns User Success
     * @throws ApiError
     */
    public getByIdUser({
        id,
    }: {
        /**
         * Get user by id
         */
        id: number,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update personal profile data
     * Update personal profile information
     * @returns User Success
     * @throws ApiError
     */
    public updateProfileUser({
        contextId,
        requestBody,
    }: {
        contextId: number,
        requestBody: UserActionUpdate,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/user/profile',
            query: {
                'contextID': contextId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * Update entry
     * Update a specific user
     * @returns User Success
     * @throws ApiError
     */
    public updateUser({
        userId,
        requestBody,
    }: {
        userId: number,
        requestBody: UserActionUpdate,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/user/{userID}',
            path: {
                'userID': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * Delete a user
     * Deletes and returns 204 or 404 depending if the request succeeds
     * @returns void
     * @throws ApiError
     */
    public deleteUser({
        userId,
    }: {
        /**
         * Will be used to remove a user
         */
        userId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/{userID}',
            path: {
                'userID': userId,
            },
        });
    }

    /**
     * Delete all user sessions older than a certain time
     * Deletes all user sessions older than a certain time
     * @returns UserSessionDeleteResult Success
     * @throws ApiError
     */
    public cleanupUserSessionsUser({
        daysOld,
    }: {
        /**
         * Delete all user sessions older than a certain time
         */
        daysOld: number,
    }): CancelablePromise<UserSessionDeleteResult> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/sessions',
            query: {
                'daysOld': daysOld,
            },
        });
    }

}
