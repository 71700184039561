/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum VideofeedVendor {
    CUSTOM = 'custom',
    FOXSPORT = 'foxsport',
    OMNISPORT = 'omnisport',
    ESPN = 'espn',
    KNVB = 'knvb',
    VIRAL = 'viral',
}
